<template>
  <div class="poster-container">
    <img
      v-if="$store.state.hospitalId === 'HS2022120788'"
      class="img"
      src="https://img.glztj.com/images/202401/500780280066117.png"
      alt=""
    />
    <img v-else class="img" src="@/assets/group-poster1.png" alt="" />

    <div
      class="button"
      @click="
        $router.push('/group-apply?hospitalId=' + $store.state.hospitalId)
      "
    ></div>
    <img class="img" src="@/assets/group-poster2.png" alt="" />
    <!-- <template v-if="$store.state.hospitalId !== 'HS2022120788'">
      <img class="img" src="@/assets/group-poster3.png" alt="" />
      <div
        class="recommend-button"
        @click="$router.push('/questionnaire-business')"
      ></div>
    </template> -->
  </div>
</template>

<script>
export default {
  mounted() {
    if (localStorage.getItem("hospitalId") != this.$route.query.hospitalId) {
      localStorage.setItem("token", "");
      localStorage.setItem("telNo", "");
      localStorage.removeItem("hospitalInfo");
    }
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
  },
};
</script>

<style scoped>
.poster-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.img {
  width: 100%;
}

.button {
  position: absolute;
  top: 540px;
  width: 100%;
  height: 40px;
}

.recommend-button {
  position: absolute;
  bottom: 57px;
  width: 100%;
  height: 40px;
}
</style>
